import React, { useState, useEffect, useRef } from "react";
import { Search } from "lucide-react";

const AddressField = ({ register, setValue, errors, initialValue, watch }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef(null);

  // Watch the municipality field
  const municipalityValue = watch("address.municipality");

  // Fetch suggestions from API
  const fetchMunicipalitySuggestions = async (query) => {
    try {
      const response = await fetch(
        `https://school.vpit.com.np/v1/findaddress?address=${encodeURIComponent(
          query
        )}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error("Error fetching municipality suggestions:", error);
      throw new Error("Failed to fetch suggestions");
    }
  };

  // Handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch suggestions when municipality input changes
  useEffect(() => {
    let timeoutId;

    const fetchSuggestions = async () => {
      if (municipalityValue && municipalityValue.length > 2) {
        setIsLoading(true);
        setError(null);

        try {
          const data = await fetchMunicipalitySuggestions(municipalityValue);
          setSuggestions(data);
          setShowSuggestions(true);
        } catch (err) {
          setError(err.message);
          setSuggestions([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    // Debounce the API call
    timeoutId = setTimeout(fetchSuggestions, 500);

    return () => clearTimeout(timeoutId);
  }, [municipalityValue]);

  const handleInputFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSelectSuggestion = (address) => {
    setValue("address.municipality", address.municipality);
    setValue("address.district", address.district);
    setValue("address.province", address.province);
    setValue("address.ward", address.ward || ""); // Provide a default if ward is missing
    setShowSuggestions(false);
  };

  return (
    <div className="grid grid-cols-2 gap-6 mb-4" ref={wrapperRef}>
      <div className="relative">
        <label className="form_label">Municipality</label>
        <div className="relative">
          <input
            type="text"
            className="form_input"
            autoComplete="off"
            {...register("address.municipality", {
              required: "Municipality is required",
            })}
            onFocus={handleInputFocus}
          />
          {isLoading ? (
            <div className="absolute right-2 top-2.5 animate-spin text-gray-400">
              {/* Spinner SVG */}
            </div>
          ) : (
            <Search
              className="absolute right-2 top-2.5 text-gray-400"
              size={20}
            />
          )}
        </div>

        {error && (
          <div className="mt-2 text-sm text-red-500">Error: {error}</div>
        )}

        {showSuggestions && suggestions.length > 0 && (
          <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-48 overflow-auto">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelectSuggestion(suggestion)}
                className="px-4 py-2 hover:bg-blue-50 cursor-pointer"
              >
                <div className="font-medium">{suggestion.municipality}</div>
                <div className="text-sm text-gray-500">
                  {suggestion.district}, {suggestion.province}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Other address fields */}
      <div>
        <label className="form_label">Ward No.</label>
        <input
          type="text"
          className="form_input"
          {...register("address.ward", { required: "Ward is required" })}
        />
        <span className="text-red-500">{errors?.address?.ward?.message}</span>
      </div>

      <div>
        <label className="form_label">District</label>
        <input
          type="text"
          readOnly
          className="form_input"
          {...register("address.district", { required: true })}
        />
      </div>

      <div>
        <label className="form_label">Province</label>
        <input
          type="text"
          readOnly
          className="form_input"
          {...register("address.province", { required: true })}
        />
      </div>
    </div>
  );
};

export default AddressField;
