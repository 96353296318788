import React from 'react'
import StudentForm from './StudentForm'

const AddStudent = () => {
  return (
    <>
    <StudentForm/>
    </>
  )
}

export default AddStudent