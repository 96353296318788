// src/layouts/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useAxiosInterceptor } from '../hooks/useAxiosInterceptor';

const MainLayout = () => {
  useAxiosInterceptor();

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Header />
        <main className="w-full lg:ps-64">
        <div class="p-4 sm:p-6 space-y-4 sm:space-y-6">

          <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
