import axiosInstance from "../lib/axiosConfig";

export const getClasses = async (page = 1, limit = 10) => {
  const offset = (page - 1) * limit;
  const response = await axiosInstance.get(`classes/`, {
    params: {
      offset,
      limit,
    },
  });
  
  return {
    data: response.data.data,
    currentPage: response.data.metadata.current_page,
    totalPages: response.data.metadata.total_pages,
    totalCount: response.data.metadata.count
  };
};

export const getClassById = async (id) => {
  const response = await axiosInstance.get(`classes/${id}`);
  return response.data;
};

export const createClass = async (classData) => {
  const response = await axiosInstance.post("classes/", classData);
  return response.data;
};

export const updateClass = async (id, classData) => {
  const response = await axiosInstance.patch(`classes/${id}`, classData);
  return response.data;
};

export const deleteClass = async (id) => {
  const response = await axiosInstance.delete(`classes/${id}`);
  return response.data;
};
