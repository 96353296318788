import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getClasses } from "../../Api/classServices";
import { useTableColumns } from "../../hooks/class/useTableColumns";
import Table from "../../components/common/Table";

const ListClass = () => {
  const navigate = useNavigate();
  const columns = useTableColumns();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: classesData, isLoading } = useQuery({
    queryKey: ["classes", currentPage],
    queryFn: () => getClasses(currentPage),
  });

  // Default pagination values
  const pagination = {
    currentPage: classesData?.currentPage || 1,
    totalPages: classesData?.totalPages || 1,
    nextPage: () => setCurrentPage(old => Math.min(old + 1, classesData?.totalPages || 1)),
    prevPage: () => setCurrentPage(old => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/classes/create";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
      </div>
      <Table
        data={classesData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Class"
        baseEditPath="/classes/edit"
      />
    </div>
  );
};

export default ListClass;
