import { useMemo } from "react";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Eye, Trash } from "lucide-react";

export const useTableColumns = (handleDelete) => {
  const navigate = useNavigate();

  return useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        id: "dob",
        header: "Date of Birth",
        accessorKey: "dob",
      },
      {
        id: "emis_id",
        header: "Emis Id",
        accessorKey: "emis_id",
      },
      {
        id: "gender",
        header: "Gender",
        accessorKey: "gender",
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
      },
      {
        id: "admitted_on",
        header: "Admitted On",
        accessorKey: "admitted_on",
        cell: (info) =>
          DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
      },
      {
        id: "action",
        enableSorting: false,
        canHide: false,
        header: "Action",
        cell: ({ row }) => (
          <div className="flex gap-2" onClick={(e) => e.stopPropagation()}> {/* Prevent row click when clicking actions */}
            <Link to={`/students/edit/${row.original.id}`}>
              <Eye size={18} />
            </Link>
            <Link to={`/students/edit/${row.original.id}`}>
              <Edit size={18} />
            </Link>
            <Link onClick={() => handleDelete(row.original.id)}>
              <Trash size={18} className="text-red-500" />
            </Link>
          </div>
        ),
      },
    ],
    [handleDelete]
  );
};
