import { useCallback } from "react";
import Swal from 'sweetalert2';
import { useTableColumns } from "./useTableColumns";
import { useParentQuery } from "./useParentQuery";
import confirmDelete from "../../lib/confirmDelete";

export const useParentTable = () => {
  // Fetch parent data and handle pagination
  const { data, error, isLoading, isFetching, pagination, deleteMutation, editMutation ,createMutation} = useParentQuery();

 // Handle delete operation
 const handleDelete = useCallback(
  (Id) => {
    confirmDelete(() => deleteMutation.mutate(Id, {
      onError: () => {
        Swal.fire('Error!', 'There was an issue deleting the item.', 'error');
      }
    }));
  },
  [deleteMutation]
);

  // Table columns setup
  const columns = useTableColumns(handleDelete);

  return {
    columns,
    data,
    isLoading,
    isFetching,
    error,
    pagination,
    editMutation,
    deleteMutation,
    createMutation
  };
};
