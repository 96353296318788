import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useStudentQuery } from "../../hooks/student/useStudentQuery";
import AddressField from "../common/addressField";
import { getStudentById } from "../../Api/studentsServices";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

// CSS classes for form styling
const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const StudentForm = () => {
  const [isPrimaryExpanded, setIsPrimaryExpanded] = useState(false);
  const [isSecondaryExpanded, setIsSecondaryExpanded] = useState(false);
  const [isEnrollmentExpanded, setIsEnrollmentExpanded] = useState(false);
  const { editMutation, createMutation } = useStudentQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: selectedStudent } = useQuery({
    queryKey: ["students", id],
    queryFn: () => getStudentById(id),
    enabled: !!id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
  } = useForm();

  // Watch fields to determine if validation should be applied
  const primaryFirstName = watch("primaryParent.first_name");
  const secondaryFirstName = watch("secondaryParent.first_name");
  const classId = watch("enrollments[0].class_id");

  useEffect(() => {
    if (selectedStudent) {
      const defaultValues = {
        ...selectedStudent,
        address: {
          municipality: selectedStudent.address?.municipality || "",
          ward: selectedStudent.address?.ward || "",
          district: selectedStudent.address?.district || "",
          province: selectedStudent.address?.province || "",
        },
        // Assign first parent as primary and second as secondary
        primaryParent: selectedStudent.parents?.[0] || {},
        secondaryParent: selectedStudent.parents?.[1] || {},
        enrollments: selectedStudent.enrollments || [
          {
            class_id: "",
            section_id: "",
            academic_year_id: "",
          },
        ],
      };
      reset(defaultValues);
      // Expand sections if they have data
      if (defaultValues.primaryParent?.first_name) {
        setIsPrimaryExpanded(true);
      }
      if (defaultValues.secondaryParent?.first_name) {
        setIsSecondaryExpanded(true);
      }
      if (defaultValues.enrollments?.[0]?.class_id) {
        setIsEnrollmentExpanded(true);
      }
    }
  }, [selectedStudent, reset]);

  const onSubmit = (data) => {
    try {
      // Create a deep copy of the data to avoid mutating the original
      let formData = JSON.parse(JSON.stringify(data));

      // Convert empty strings to null for specific fields
      const fieldsToCheck = [
        'religion',
        'caste',
        'disability',
        'mother_tongue',
        'blood_group',
        'notes'
      ];

      fieldsToCheck.forEach(field => {
        if (formData[field] === '') {
          formData[field] = null;
        }
      });

      // Handle parent data
      const handleParentData = (parent, originalParent) => {
        if (!parent) return null;
        if (!parent.first_name && !Object.values(parent).some(value => value)) return null;
        
        const processedParent = {
          ...originalParent, // Preserve original data like id and version
          ...parent,
        };
        
        Object.keys(processedParent).forEach(key => {
          if (key !== 'id' && key !== 'version') { // Don't convert id and version to null
            processedParent[key] = processedParent[key] === '' ? null : processedParent[key];
          }
        });
        
        return processedParent;
      };

      // Get original parent data to preserve IDs and versions
      const originalPrimaryParent = selectedStudent?.parents?.[0];
      const originalSecondaryParent = selectedStudent?.parents?.[1];

      formData.primaryParent = handleParentData(formData.primaryParent, originalPrimaryParent);
      formData.secondaryParent = handleParentData(formData.secondaryParent, originalSecondaryParent);

      // Handle enrollment data
      if (!formData.enrollments?.[0]?.class_id) {
        formData.enrollments = null;
      } else if (formData.enrollments?.[0]) {
        Object.keys(formData.enrollments[0]).forEach(key => {
          if (formData.enrollments[0][key] === '') {
            formData.enrollments[0][key] = null;
          }
        });
      }

      // Handle address
      if (formData.address) {
        Object.keys(formData.address).forEach(key => {
          if (formData.address[key] === '') {
            formData.address[key] = null;
          }
        });
      }

      // Prepare parents array
      const parents = [];
      if (formData.primaryParent) {
        parents.push({ ...formData.primaryParent, relationship: formData.primaryParent.relationship || "Primary" });
      }
      if (formData.secondaryParent) {
        parents.push({ ...formData.secondaryParent, relationship: formData.secondaryParent.relationship || "Secondary" });
      }

      // Remove unnecessary fields and prepare final data
      const {
        primaryParent,
        secondaryParent,
        profile_picture,
        student_parents, // Remove this as it's not needed in the payload
        ...restData
      } = formData;

      const finalData = {
        ...restData,
        parents
      };

      console.log('Submitting data:', finalData);

      if (id) {
        editMutation.mutate({ id, updatedData: finalData });
      } else {
        createMutation.mutate(finalData);
      }
      navigate("/students/all");
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-6xl mx-auto p-6 border rounded-lg"
    >
      <h3 className="text-lg font-semibold mb-4">Student's Detail <span className="text-sm text-gray-500">(* Required fields)</span></h3>
      <div className="grid grid-cols-2 gap-6 mb-4">
        <div>
          <label className="form_label">First Name <span className="text-red-500">*</span></label>
          <input
            className={`form_input ${errors.first_name && requiredFieldClass}`}
            type="text"
            {...register("first_name", { required: "Required" })}
          />
          {errors.first_name && (
            <span className={errorMessageClass}>{errors.first_name.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Last Name <span className="text-red-500">*</span></label>
          <input
            className={`form_input ${errors.last_name && requiredFieldClass}`}
            type="text"
            {...register("last_name", { required: "Required" })}
          />
          {errors.last_name && (
            <span className={errorMessageClass}>{errors.last_name.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Date of Birth <span className="text-red-500">*</span></label>
          <input
            className={`form_input ${errors.dob && requiredFieldClass}`}
            type="date"
            {...register("dob", { required: "Required" })}
          />
          {errors.dob && (
            <span className={errorMessageClass}>{errors.dob.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">EMIS ID <span className="text-red-500">*</span></label>
          <input
            className={`form_input ${errors.emis_id && requiredFieldClass}`}
            type="text"
            {...register("emis_id", { required: "Required" })}
          />
          {errors.emis_id && (
            <span className={errorMessageClass}>{errors.emis_id.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Gender <span className="text-red-500">*</span></label>
          <select
            className={`form_input ${errors.gender && requiredFieldClass}`}
            {...register("gender", { required: "Required" })}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {errors.gender && (
            <span className={errorMessageClass}>{errors.gender.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Status <span className="text-red-500">*</span></label>
          <select
            className={`form_input ${errors.status && requiredFieldClass}`}
            {...register("status", { required: "Required" })}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          {errors.status && (
            <span className={errorMessageClass}>{errors.status.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Religion (Optional)</label>
          <select
            className="form_input"
            {...register("religion")}
          >
            <option value="">Select Religion</option>
            <option value="hinduism">Hinduism</option>
            <option value="islam">Islam</option>
            <option value="buddhism">Buddhism</option>
            <option value="christianity">Christianity</option>
            <option value="kirat_mundhum">Kirat Mundhum</option>
            <option value="prakriti">Prakriti</option>
            <option value="bon">Bon</option>
            <option value="sikhism">Sikhism</option>
            <option value="jainism">Jainism</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div>
          <label className="form_label">Caste (Optional)</label>
          <select
            className="form_input"
            {...register("caste")}
          >
            <option value="">Select Caste</option>
            <option value="dalit">Dalit</option>
            <option value="janajati">Janajati</option>
            <option value="brahmin">Brahmin</option>
            <option value="chhetri">Chhetri</option>
            <option value="other">Other</option>
          </select>
          {errors.caste && (
            <span className={errorMessageClass}>{errors.caste.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Disability (Optional)</label>
          <select
            className="form_input"
            {...register("disability")}
          >
            <option value="">Select Disability</option>
            <option value="none">None</option>
            <option value="physical">Physical</option>
            <option value="mental">Mental</option>
            <option value="deaf">Deaf</option>
            <option value="blind">Blind</option>
            <option value="low_vision">Low Vision</option>
            <option value="deaf_blind">Deaf-Blind</option>
            <option value="speech_impairment">Speech Impairment</option>
            <option value="multiple_disabilities">Multiple Disabilities</option>
            <option value="hearing">Hearing</option>
          </select>
          {errors.disability && (
            <span className={errorMessageClass}>{errors.disability.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Mother Tongue (Optional)</label>
          <select
            className="form_input"
            {...register("mother_tongue")}
          >
            <option value="">Select Mother Tongue</option>
            <option value="nepali">Nepali</option>
            <option value="maithili">Maithili</option>
            <option value="english">English</option>
            <option value="hindi">Hindi</option>
            <option value="bhojpuri">Bhojpuri</option>
            <option value="gurung">Gurung</option>
            <option value="rai">Rai</option>
            <option value="tamang">Tamang</option>
            <option value="sherpa">Sherpa</option>
            <option value="sanskrit">Sanskrit</option>
            <option value="newari">Newari</option>
            <option value="kirati">Kirati</option>
            <option value="tharu">Tharu</option>
            <option value="magar">Magar</option>
            <option value="urdu">Urdu</option>
            <option value="rajbanshi">Rajbanshi</option>
            <option value="tajpuriya">Tajpuriya</option>
            <option value="majhi">Majhi</option>
            <option value="thami">Thami</option>
            <option value="dhimal">Dhimal</option>
            <option value="baitadeli">Baitadeli</option>
            <option value="achhami">Achhami</option>
            <option value="tibetian">Tibetian</option>
            <option value="doteli">Doteli</option>
            <option value="limbu">Limbu</option>
            <option value="sunuwar">Sunuwar</option>
            <option value="yakkha">Yakkha</option>
            <option value="bhujel">Bhujel</option>
            <option value="kulung">Kulung</option>
            <option value="kumal">Kumal</option>
            <option value="other">Other</option>
          </select>
          {errors.mother_tongue && (
            <span className={errorMessageClass}>{errors.mother_tongue.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Blood Group (Optional)</label>
          <select
            className="form_input"
            {...register("blood_group")}
          >
            <option value="">Select Blood Group</option>
            <option value="a+">A+</option>
            <option value="a-">A-</option>
            <option value="b+">B+</option>
            <option value="b-">B-</option>
            <option value="ab+">AB+</option>
            <option value="ab-">AB-</option>
            <option value="o+">O+</option>
            <option value="o-">O-</option>
            <option value="unknown">Unknown</option>
          </select>
          {errors.blood_group && (
            <span className={errorMessageClass}>{errors.blood_group.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">Admitted On <span className="text-red-500">*</span></label>
          <input
            className={`form_input ${errors.admitted_on && requiredFieldClass}`}
            type="date"
            {...register("admitted_on", { required: "Required" })}
          />
          {errors.admitted_on && (
            <span className={errorMessageClass}>{errors.admitted_on.message}</span>
          )}
        </div>

        <div className="mb-4 col-span-2">
          <label className="form_label">Notes (Optional)</label>
          <textarea className="form_input" {...register("notes")} />
        </div>

        <div className="mb-4 col-span-2">
          <label className="form_label">Profile Picture:</label>
          <input
            className="form_input"
            type="file"
            {...register("profile_picture")}
          />
        </div>
      </div>

      <h3 className="text-lg font-semibold mb-4">Address Details</h3>
      <AddressField
        register={register}
        setValue={setValue}
        watch={watch}
        initialValue={selectedStudent?.address}
        errors={errors}
      />

      <div className="mb-8">
        <button
          type="button"
          className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          onClick={() => setIsPrimaryExpanded(!isPrimaryExpanded)}
        >
          <h3 className="text-lg font-semibold">Primary Parent's Detail {primaryFirstName && <span className="text-sm text-gray-500">(* Required fields)</span>}</h3>
          {isPrimaryExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </button>

        {isPrimaryExpanded && (
          <div className="grid grid-cols-2 gap-6 mt-4 p-4 border rounded-lg">
            <div>
              <label className="form_label">First Name {primaryFirstName && <span className="text-red-500">*</span>}</label>
              <input
                className={`form_input ${errors.primaryParent?.first_name && requiredFieldClass}`}
                type="text"
                {...register("primaryParent.first_name")}
              />
              {errors.primaryParent?.first_name && (
                <span className={errorMessageClass}>{errors.primaryParent.first_name.message}</span>
              )}
            </div>

            {primaryFirstName && (
              <>
                <div>
                  <label className="form_label">Last Name <span className="text-red-500">*</span></label>
                  <input
                    className={`form_input ${errors.primaryParent?.last_name && requiredFieldClass}`}
                    type="text"
                    {...register("primaryParent.last_name", { 
                      required: "Last name is required" 
                    })}
                  />
                  {errors.primaryParent?.last_name && (
                    <span className={errorMessageClass}>{errors.primaryParent.last_name.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Date of Birth <span className="text-red-500">*</span></label>
                  <input
                    className={`form_input ${errors.primaryParent?.dob && requiredFieldClass}`}
                    type="date"
                    {...register("primaryParent.dob", { 
                      required: "Date of birth is required" 
                    })}
                  />
                  {errors.primaryParent?.dob && (
                    <span className={errorMessageClass}>{errors.primaryParent.dob.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Gender <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.primaryParent?.gender && requiredFieldClass}`}
                    {...register("primaryParent.gender", { 
                      required: "Gender is required" 
                    })}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {errors.primaryParent?.gender && (
                    <span className={errorMessageClass}>{errors.primaryParent.gender.message}</span>
                  )}
                </div>
              </>
            )}

            <div>
              <label className="form_label">Address (Optional)</label>
              <input
                className="form_input"
                type="text"
                {...register("primaryParent.address")}
              />
            </div>

            <div>
              <label className="form_label">Phone (Optional)</label>
              <input
                className="form_input"
                type="text"
                {...register("primaryParent.phone")}
              />
            </div>

            <div>
              <label className="form_label">Email (Optional)</label>
              <input
                className="form_input"
                type="email"
                {...register("primaryParent.email")}
              />
            </div>

            <div className="col-span-2">
              <label className="form_label">Notes (Optional)</label>
              <textarea
                className="form_input"
                {...register("primaryParent.notes")}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mb-8">
        <button
          type="button"
          className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          onClick={() => setIsSecondaryExpanded(!isSecondaryExpanded)}
        >
          <h3 className="text-lg font-semibold">Secondary Parent's Detail {secondaryFirstName && <span className="text-sm text-gray-500">(* Required fields)</span>}</h3>
          {isSecondaryExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </button>

        {isSecondaryExpanded && (
          <div className="grid grid-cols-2 gap-6 mt-4 p-4 border rounded-lg">
            <div>
              <label className="form_label">First Name {secondaryFirstName && <span className="text-red-500">*</span>}</label>
              <input
                className={`form_input ${errors.secondaryParent?.first_name && requiredFieldClass}`}
                type="text"
                {...register("secondaryParent.first_name")}
              />
              {errors.secondaryParent?.first_name && (
                <span className={errorMessageClass}>{errors.secondaryParent.first_name.message}</span>
              )}
            </div>

            {secondaryFirstName && (
              <>
                <div>
                  <label className="form_label">Last Name <span className="text-red-500">*</span></label>
                  <input
                    className={`form_input ${errors.secondaryParent?.last_name && requiredFieldClass}`}
                    type="text"
                    {...register("secondaryParent.last_name", { 
                      required: "Last name is required"
                    })}
                  />
                  {errors.secondaryParent?.last_name && (
                    <span className={errorMessageClass}>{errors.secondaryParent.last_name.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Date of Birth <span className="text-red-500">*</span></label>
                  <input
                    className={`form_input ${errors.secondaryParent?.dob && requiredFieldClass}`}
                    type="date"
                    {...register("secondaryParent.dob", { 
                      required: "Date of birth is required"
                    })}
                  />
                  {errors.secondaryParent?.dob && (
                    <span className={errorMessageClass}>{errors.secondaryParent.dob.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Gender <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.secondaryParent?.gender && requiredFieldClass}`}
                    {...register("secondaryParent.gender", { 
                      required: "Gender is required"
                    })}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {errors.secondaryParent?.gender && (
                    <span className={errorMessageClass}>{errors.secondaryParent.gender.message}</span>
                  )}
                </div>
              </>
            )}

            <div>
              <label className="form_label">Address (Optional)</label>
              <input
                className="form_input"
                type="text"
                {...register("secondaryParent.address")}
              />
            </div>

            <div>
              <label className="form_label">Phone (Optional)</label>
              <input
                className="form_input"
                type="text"
                {...register("secondaryParent.phone")}
              />
            </div>

            <div>
              <label className="form_label">Email (Optional)</label>
              <input
                className="form_input"
                type="email"
                {...register("secondaryParent.email")}
              />
            </div>

            <div className="col-span-2">
              <label className="form_label">Notes (Optional)</label>
              <textarea
                className="form_input"
                {...register("secondaryParent.notes")}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mb-8">
        <button
          type="button"
          className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          onClick={() => setIsEnrollmentExpanded(!isEnrollmentExpanded)}
        >
          <h3 className="text-lg font-semibold">Enrollment {classId && <span className="text-sm text-gray-500">(* Required fields)</span>}</h3>
          {isEnrollmentExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </button>

        {isEnrollmentExpanded && (
          <div className="grid grid-cols-3 gap-6 mt-4 p-4 border rounded-lg">
            <div>
              <label className="form_label">Class {classId && <span className="text-red-500">*</span>}</label>
              <select
                className={`form_input ${errors.enrollments?.[0]?.class_id && requiredFieldClass}`}
                {...register("enrollments[0].class_id")}
              >
                <option value="">Select Class</option>
                <option value="1">Class 1</option>
                <option value="2">Class 2</option>
                <option value="3">Class 3</option>
                <option value="4">Class 4</option>
              </select>
              {errors.enrollments?.[0]?.class_id && (
                <span className={errorMessageClass}>{errors.enrollments[0].class_id.message}</span>
              )}
            </div>

            {classId && (
              <>
                <div>
                  <label className="form_label">Section <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.enrollments?.[0]?.section_id && requiredFieldClass}`}
                    {...register("enrollments[0].section_id", {
                      required: "Section is required"
                    })}
                  >
                    <option value="">Select Section</option>
                    <option value="1">Section A</option>
                    <option value="2">Section B</option>
                    <option value="3">Section C</option>
                  </select>
                  {errors.enrollments?.[0]?.section_id && (
                    <span className={errorMessageClass}>{errors.enrollments[0].section_id.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Academic Year <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.enrollments?.[0]?.academic_year_id && requiredFieldClass}`}
                    {...register("enrollments[0].academic_year_id", {
                      required: "Academic year is required"
                    })}
                  >
                    <option value="">Select Year</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                  </select>
                  {errors.enrollments?.[0]?.academic_year_id && (
                    <span className={errorMessageClass}>{errors.enrollments[0].academic_year_id.message}</span>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Submit Button */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={() => navigate(-1)}
          className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
        >
          cancel
        </button>

        <button
          type="submit"
          className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        >
          {selectedStudent ? "update" : "save"}
        </button>
      </div>
    </form>
  );
};

export default StudentForm;
