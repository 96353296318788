import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllStudents, deleteStudent, editStudent, createStudent } from "../../Api/studentsServices";
import usePagination from "../usePagination";
import { toast } from "react-toastify";

// Handles data fetching and pagination
export const useStudentQuery = () => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } = usePagination();

  // Fetching students with pagination
  const { data = { data: [], metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 } }, error, isLoading, isFetching } = useQuery({
    queryKey: ["students", offset, limit],
    queryFn: () => getAllStudents({ offset, limit }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: deleteStudent,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete student. Please try again.");
    },
  });

  // Edit mutation
  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => editStudent(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update student. Please try again.");
    },
  });

  // Create Mutation
  const createMutation = useMutation({
    mutationFn: createStudent,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create student. Please try again.");
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    pagination: {
      currentPage,
      totalPages,
      nextPage: () => nextPage(totalPages),
      prevPage,
      goToPage: (page) => goToPage(page, totalPages),
    },
    deleteMutation,
    editMutation,
    createMutation
  };
};