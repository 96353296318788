import React from 'react'
import ParentForm from './ParentForm'

const AddParent = () => {
  return (
    <>
      <ParentForm />
    </>
  )
}

export default AddParent
