// src/routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import ListStudent from "./pages/Student/ListStudent";
import CreateStudent from "./pages/Student/CreateStudent";
import StudentForm from "./components/Student/StudentForm";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Register from './pages/Auth/Register';
import Login from "./pages/Auth/Login";
import ListParent from "./pages/parent/ListParent";
import CreateParent from "./pages/parent/CreateParent";
import ParentForm from "./components/Parent/ParentForm";
import ListClass from "./pages/Class/ListClass";
import CreateClass from "./pages/Class/CreateClass";
import ClassForm from "./components/Class/ClassForm";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          
          {/* Grouped Students Routes */}
          <Route path="students">
            <Route path="all" element={<ListStudent />} />
            <Route path="create" element={<CreateStudent />} />
            <Route path="edit/:id" element={<StudentForm />} />
          </Route>

          {/* Grouped Parents Routes */}
          <Route path="parents">
            <Route path="all" element={<ListParent />} />
            <Route path="create" element={<CreateParent />} />
            <Route path="edit/:id" element={<ParentForm />} />
          </Route>

          {/* Grouped Classes Routes */}
          <Route path="classes">
            <Route path="all" element={<ListClass />} />
            <Route path="create" element={<CreateClass />} />
            <Route path="edit/:id" element={<ClassForm />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
