import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/authContext';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Register = () => {
    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const { registerUser } = useAuth();

    const firstName = watch('first_name');
    const lastName = watch('last_name');

    useEffect(() => {
        if (firstName && lastName) {
            const username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`.replace(/\s+/g, '');
            setValue('username', username);
        }
    }, [firstName, lastName, setValue]);

    const onSubmit = (data) => {
        registerUser(data);
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-2xl font-semibold text-center mb-6">Register</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                First Name:
                            </label>
                            <input
                                id="first_name"
                                type="text"
                                {...register('first_name', { required: 'First name is required' })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            {errors.first_name && (
                                <span className="text-red-500 text-sm">{errors.first_name.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                Last Name:
                            </label>
                            <input
                                id="last_name"
                                type="text"
                                {...register('last_name', { required: 'Last name is required' })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            {errors.last_name && (
                                <span className="text-red-500 text-sm">{errors.last_name.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email:
                            </label>
                            <input
                                id="email"
                                type="email"
                                {...register('email', {
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Invalid email address'
                                    }
                                })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            {errors.email && (
                                <span className="text-red-500 text-sm">{errors.email.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                Phone:
                            </label>
                            <PhoneInput
                                country={'np'}
                                value={watch('phone')}
                                onChange={phone => setValue('phone', phone)}
                                inputProps={{
                                    id: 'phone',
                                    required: true,
                                }}
                                containerClass="mt-1"
                                inputClass="!w-full !px-3 !py-2 !border !border-gray-300 !rounded-md !shadow-sm focus:!outline-none focus:!ring-indigo-500 focus:!border-indigo-500 !sm:text-sm !pl-12"
                                buttonClass="!border !border-gray-300 !rounded-l-md !shadow-sm"
                                buttonStyle={{ borderRight: 0 }}
                                containerStyle={{ minWidth: '100%' }}
                                dropdownStyle={{ width: '300px' }}
                            />
                            {errors.phone && (
                                <span className="text-red-500 text-sm">{errors.phone.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                Username:
                            </label>
                            <input
                                id="username"
                                type="text"
                                {...register('username', { required: 'Username is required' })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            {errors.username && (
                                <span className="text-red-500 text-sm">{errors.username.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password:
                            </label>
                            <input
                                id="password"
                                type="password"
                                {...register('password', { required: 'Password is required' })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            {errors.password && (
                                <span className="text-red-500 text-sm">{errors.password.message}</span>
                            )}
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="roles" className="block text-sm font-medium text-gray-700">
                                Register as:
                            </label>
                            <select
                                id="roles"
                                multiple
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                {...register('roles', { 
                                    required: 'At least one role is required',
                                    validate: value => value.length > 0 || 'Please select at least one role',
                                    setValueAs: value => Array.isArray(value) ? value : [value]
                                })}
                            >
                                <option value="student">Student</option>
                                <option value="parent">Parent</option>
                                <option value="teacher">Teacher</option>
                            </select>
                            {errors.roles && (
                                <span className="text-red-500 text-sm">{errors.roles.message}</span>
                            )}
                            <p className="mt-1 text-sm text-gray-500">
                                You can select multiple roles if applicable
                            </p>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Register
                    </button>
                    <p className="mt-4 text-sm text-center">
                        Already have an account? <Link to="/login" className="text-indigo-600 hover:text-indigo-700">Login</Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Register;