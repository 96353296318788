import React, { useEffect } from "react";

function HideColumn({ table }) {
  useEffect(() => {
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, []);
  return (
    <>
      {/* <!-- Dropdown --> */}
      <div className="mt-1 mx-1 sm:mt-1 hs-dropdown [--auto-close:inside] relative sm:inline-flex z-20">
        <button
          id="hs-dropdown-auto-close-inside"
          type="button"
          className="hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-label="Dropdown"
        >
          <svg
            class="flex-none size-4 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
        </button>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden bg-white shadow-md rounded-lg mt-2"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="hs-dropdown-auto-close-inside"
        >
          <div className="p-1 space-y-0.5">
            {table
              .getAllLeafColumns()
              .filter((column) => column.columnDef?.canHide !== false) // Filter out columns with canHide: false
              .map((column) => (
                <div
                  className="flex items-center gap-x-2 py-2 px-3 rounded-lg hover:bg-gray-100"
                  key={column.id}
                >
                  <input
                    id="hs-dropdown-item-checkbox-archive"
                    name="hs-dropdown-item-checkbox-archive"
                    type="checkbox"
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                    className="shrink-0 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                  <label
                    for="hs-dropdown-item-checkbox-archive"
                    className="grow"
                  >
                    <span className="block text-sm text-gray-800">
                      {column.columnDef.header}
                    </span>
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* <!-- End Dropdown --> */}
    </>
  );
}

export default HideColumn;

