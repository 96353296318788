import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
});

// Add a request interceptor to include the token if available
axiosInstance.interceptors.request.use(
  (config) => {
  const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
