import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AddressField from "../common/addressField";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getParentById } from "../../Api/parentServices";
import { useParentQuery } from "../../hooks/parent/useParentQuery";

const ParentForm = () => {
  const { editMutation, createMutation } = useParentQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: selectedParent } = useQuery({
    queryKey: ["parents", id],
    queryFn: () => getParentById(id),
    enabled: !!id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm();

  useEffect(() => {
    if (selectedParent) {
      reset(selectedParent);
    }
  }, [selectedParent, reset]);

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      dob: data.dob || null,
      address: {
        municipality: data.address?.municipality || '',
        ward: data.address?.ward || '',
        district: data.address?.district || '',
        province: data.address?.province || '',
      }
    };

    if (id) {
      editMutation.mutate(formattedData);
    } else {
      createMutation.mutate(formattedData);
    }
    navigate("/parents/all");
  };

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-6">
          {id ? "Edit Parent" : "Add Parent"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            {/* Personal Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                {...register("first_name", { required: "First name is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.first_name && (
                <span className="text-red-500 text-sm">
                  {errors.first_name.message}
                </span>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                {...register("last_name", { required: "Last name is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.last_name && (
                <span className="text-red-500 text-sm">
                  {errors.last_name.message}
                </span>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date of Birth
              </label>
              <input
                type="date"
                {...register("dob", { required: "Date of birth is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.dob && (
                <span className="text-red-500 text-sm">{errors.dob.message}</span>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Gender
              </label>
              <select
                {...register("gender", { required: "Gender is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.gender && (
                <span className="text-red-500 text-sm">
                  {errors.gender.message}
                </span>
              )}
            </div>

            {/* <div>
              <label className="block text-sm font-medium text-gray-700">
                Relationship
              </label>
              <select
                {...register("relationship", { required: "Relationship is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select Relationship</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="guardian">Guardian</option>
              </select>
              {errors.relationship && (
                <span className="text-red-500 text-sm">
                  {errors.relationship.message}
                </span>
              )}
            </div> */}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.email && (
                <span className="text-red-500 text-sm">
                  {errors.email.message}
                </span>
              )}
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone:
              </label>
              <PhoneInput
                country={'np'}
                value={watch('phone')}
                onChange={phone => setValue('phone', phone)}
                inputProps={{
                  id: 'phone',
                  required: true,
                }}
                containerClass="mt-1"
                inputClass="!w-full !px-3 !py-2 !border !border-gray-300 !rounded-md !shadow-sm focus:!outline-none focus:!ring-indigo-500 focus:!border-indigo-500 !sm:text-sm !pl-12"
                buttonClass="!border !border-gray-300 !rounded-l-md !shadow-sm"
                buttonStyle={{ borderRight: 0 }}
                containerStyle={{ minWidth: '100%' }}
                dropdownStyle={{ width: '300px' }}
              />
              {errors.phone && (
                <span className="text-red-500 text-sm">
                  {errors.phone.message}
                </span>
              )}
            </div>
          </div>

          {/* Address */}
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Address</h3>
            <AddressField 
              register={register} 
              errors={errors} 
              setValue={setValue}
              watch={watch}
            />
          </div>

          {/* Notes */}
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              {...register("notes")}
              rows={4}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate("/parents/all")}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {id ? "Update" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParentForm;
