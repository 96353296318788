import { useState } from "react";

// Hook to manage pagination state and logic
const usePagination = (defaultLimit = 10) => {
    const [limit, setLimit] = useState(defaultLimit);
    const [offset, setOffset] = useState(0);
  
    // Calculate current page based on offset and limit
    const currentPage = Math.floor(offset / limit) + 1;
  
    const goToPage = (page, totalPages) => {
      if (page >= 1 && page <= totalPages) {
        setOffset((page - 1) * limit);
      }
    };
  
    const nextPage = (totalPages) => {
      if (currentPage < totalPages) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    };
  
    const prevPage = () => {
      if (currentPage > 1) {
        setOffset((prevOffset) => Math.max(0, prevOffset - limit));
      }
    };
  
    return { limit, offset, setOffset, currentPage, goToPage, nextPage, prevPage };
  };
  
  export default usePagination;