import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createClass, deleteClass, updateClass } from "../../Api/classServices";
import { toast } from "react-toastify";

export const useClassQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createClass,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["classes"] });
      toast.success("Class created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create class. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateClass(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["classes"] });
      toast.success("Class updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update class. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteClass,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["classes"] });
      toast.success("Class deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete class. Please try again.");
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
